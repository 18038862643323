/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$body-font-family: "Poppins", sans-serif;
$main-color: #347a2a;
$optional-color: #558b2f;
$white-color: #ffffff;
$body-color: #7e8c8d;
$black-color: #2e5233;
$span-color: #a5d6a7;
$transition: all 0.7s;

body {
    font-family: $body-font-family;
    font-size: 15px;
    color: $body-color;
}
img {
    max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black-color;
    font-weight: 600;
}
a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}
button:focus {
    outline: 0;
}
p {
    margin-bottom: 10px;
    line-height: 1.7;
    color: $body-color;

    &:last-child {
        margin-bottom: 0;
    }
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-top: 30px;
    padding-bottom: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}
.d-table {
    width: 100%;
    height: 100%;

    .d-table-cell {
        vertical-align: middle;
    }
}
.default-btn-one {
    background-color: $main-color;
    color: $white-color;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 2px;
    border: 1px solid $main-color;

    &:hover {
        background: $optional-color;
        border: 1px solid $optional-color;
        color: $white-color;
    }
}
.default-btn-two {
    background-color: $optional-color;
    color: $white-color;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 2px;
    border: 1px solid $optional-color;

    &:hover {
        background: $main-color;
        border-color: $main-color;
        color: $white-color;
    }
}
.section-title {
    text-align: center;
    margin-bottom: 60px;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;

    span {
        font-size: 13px;
        font-weight: 600;
        display: block;
        margin-bottom: 15px;
        color: $span-color;
        line-height: 1;
    }
    h2 {
        font-size: 38px;
        line-height: 1.3;
        margin-bottom: 0;
    }
}
.bg-f9f9f9 {
    background-color: #f9f9f9;
}

/*================================================
Top Heder
=================================================*/
.header-area {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1041;
    width: 100%;
}

/*================================================
Navbar Area
=================================================*/
.navbar-area {
    background-color: transparent;
    width: 100%;
    height: auto;
    z-index: 999;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding: {
            top: 5px;
            bottom: 5px;
        }
    }
    &.navbar-color-white {
        &.is-sticky {
            background-color: $black-color !important;
        }
    }
}
.floza-responsive-nav {
    display: none;
}
.floza-nav {
    background: $white-color;
    padding: 3px 0;

    .navbar {
        position: inherit;
        padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .navbar-brand {
            font-size: inherit;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            &.ml-50 {
                margin-left: 50px;
            }
            .nav-item {
                position: relative;
                margin: {
                    left: 15px;
                    right: 15px;
                }
                a {
                    color: $black-color;
                    font: {
                        size: 15px;
                        weight: 500;
                    }
                    padding: {
                        left: 0;
                        right: 0;
                        top: 20px;
                        bottom: 20px;
                    }
                    i {
                        font-size: 20px;
                        line-height: 0;
                        position: relative;
                        top: 4px;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $main-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover,
                &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    border: none;
                    top: 65px;
                    left: 0;
                    z-index: 99;
                    opacity: 0;
                    width: 250px;
                    display: block;
                    border-radius: 0;
                    padding: 10px 0;
                    margin-top: 15px;
                    position: absolute;
                    visibility: hidden;
                    background: $white-color;
                    transition: all 0.2s ease-in-out;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    li {
                        margin: 0;

                        a {
                            padding: 6px 20px;
                            position: relative;
                            display: block;
                            color: $black-color;
                            font: {
                                size: 14px;
                            }
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 15px;
                            }
                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            top: 0;
                            opacity: 0;
                            left: 100%;
                            margin-top: 15px;
                            visibility: hidden;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    top: 0;
                                    opacity: 0;
                                    left: 250px;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: $black-color;

                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            top: 0;
                                            opacity: 0;
                                            left: 100%;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: $black-color;

                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    top: 0;
                                                    opacity: 0;
                                                    left: 250px;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: $black-color;

                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            top: 0;
                                                            opacity: 0;
                                                            left: 100%;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: $black-color;

                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    left: 250px;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: $black-color;

                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-option {
            background-color: #7d7d7d;
            border-radius: 30px;
            padding: 10px 18px 3px;
            margin-left: auto;

            .option-item {
                margin-left: 15px;
                padding-left: 15px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: -2px;
                    top: -4px;
                    background-color: #fa6a45;
                    height: 25px;
                    width: 1.5px;
                }
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
            .side-menu-btn {
                i {
                    cursor: pointer;
                    font-size: 35px;
                    position: relative;
                    top: 4px;
                    line-height: 0;
                    transition: $transition;
                    color: $white-color;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
            .search-box {
                i {
                    cursor: pointer;
                    font-size: 22px;
                    color: $white-color;
                    position: relative;
                    top: -2px;
                    line-height: 1;
                    transition: $transition;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
            .cart-btn {
                a {
                    display: inline-block;
                    position: relative;
                    font-size: 25px;
                    color: $white-color;
                    line-height: 1;
                    padding-right: 10px;

                    span {
                        position: absolute;
                        right: 0;
                        top: -3px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: $black-color;
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .navbar-area {
        background-color: $white-color;
        padding: {
            top: 15px;
            bottom: 15px;
        }
        &.is-sticky {
            padding: {
                top: 15px;
                bottom: 15px;
            }
        }
    }
    .floza-responsive-nav {
        display: block;

        .floza-responsive-menu {
            position: relative;

            &.mean-container {
                .mean-nav {
                    margin-top: 80px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: $main-color;
                                }
                            }
                            li {
                                a {
                                    cursor: pointer;
                                    font-size: 14.5px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    padding-top: 25px;
                    color: $main-color;

                    span {
                        background: $main-color;
                    }
                }
            }
        }
        .others-option {
            display: none !important;
            background-color: $main-color;
            border-radius: 30px;
            padding: 10px 18px 3px;

            .option-item {
                margin-left: 15px;
                padding-left: 15px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: -2px;
                    top: -4px;
                    background-color: #fa6a45;
                    height: 25px;
                    width: 1.5px;
                }
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
            .side-menu-btn {
                i {
                    cursor: pointer;
                    font-size: 35px;
                    position: relative;
                    top: 4px;
                    line-height: 0;
                    transition: $transition;
                    color: $white-color;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
            .search-box {
                i {
                    cursor: pointer;
                    font-size: 22px;
                    color: $white-color;
                    position: relative;
                    top: -2px;
                    line-height: 1;
                    transition: $transition;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
            .cart-btn {
                a {
                    display: inline-block;
                    position: relative;
                    font-size: 25px;
                    color: $white-color;
                    line-height: 1;
                    padding-right: 10px;

                    span {
                        position: absolute;
                        right: 0;
                        top: -3px;
                        width: 18px;
                        height: 18px;
                        text-align: center;
                        line-height: 20px;
                        border-radius: 50%;
                        background-color: $black-color;
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 60%;
            z-index: 999;
        }
    }
    .floza-nav {
        display: none;
    }
    .navbar-color-white {
        .floza-responsive-nav {
            .mean-container {
                a {
                    &.meanmenu-reveal {
                        color: $white-color;

                        span {
                            background: $white-color;
                        }
                    }
                }
            }
        }
        .others-option-for-responsive {
            .dot-menu {
                .inner {
                    .circle {
                        background-color: $white-color;
                    }
                }
            }
        }
    }
    .others-option-for-responsive {
        display: block;
    }
}

/*================================================
Hero Slider Area
=================================================*/
.hero-slider {
    position: relative;

    .hero-slider-item {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 830px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            background: $black-color;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.6;
        }
        &.item-bg1 {
            background-image: url("assets/img/hero-slider/slider1.jpg");
        }
    }
    .banner-content {
        position: relative;
        max-width: 720px;

        span {
            color: $white-color;
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            margin-bottom: 15px;
        }
        h1 {
            color: $white-color;
            font-size: 70px;
            margin-bottom: 15px;
        }
        p {
            color: $white-color;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 40px;
            line-height: 1.8;
        }
        .default-btn-two {
            color: $black-color;
            background-color: $white-color;
            border-color: $white-color;

            &:hover {
                background-color: $optional-color;
                color: $white-color;
                border-color: $optional-color;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            margin: 0;

            .owl-prev,
            .owl-next {
                position: absolute;
                left: 15px;
                height: 50px;
                width: 40px;
                text-align: center;
                top: 50%;
                font-size: 40px;
                line-height: 1;
                color: #f3f2f3;
                margin: -30px 0 0;

                &:hover {
                    background-color: $black-color;
                }
            }
            .owl-next {
                left: auto;
                right: 15px;
            }
        }
    }
}

/*================================================
Hero Contact Area
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;

    &.mb-85 {
        bottom: 85px;
    }
    .contact-content {
        background: $optional-color;
        border-radius: 5px;
        padding: 50px 30px 20px;

        .contact-card {
            padding-left: 55px;
            position: relative;
            margin-bottom: 30px;

            i {
                background: $white-color;
                color: $main-color;
                font-size: 30px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 5px;
                position: absolute;
                left: 0;
                top: 0;
            }
            h4 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
                color: $white-color;
            }
            p {
                font-size: 14px;
                margin-bottom: 0;
                color: $white-color;

                a {
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
About Area
=================================================*/
.about-area {
    .about-contant {
        margin-top: 30px;

        .section-title {
            text-align: left;
            margin-bottom: 20px;
            max-width: none;
        }
        .about-text {
            .mb-20 {
                margin-bottom: 25px;
            }
            .btn-bs {
                box-shadow: 1px 20px 20px 0px #e8e8e8;
                margin-top: 20px;
            }
        }
    }
    .about-image {
        position: relative;

        img {
            width: 100%;
        }
    }
    .col-lg-3 {
        position: relative;
    }
    .about-tabs {
        position: absolute;
        width: 360px;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        .title {
            font-size: 25px;
            margin-bottom: 15px;
        }
        .tab-contant {
            background: #f3fafd;
            padding: 30px 20px;
            box-shadow: 3px 7px 20px 0px #e8e8e8;
            border: 8px solid $white-color;
            border-radius: 4px;

            .nav-tabs {
                border: none;

                .nav-link {
                    font-size: 16px;
                    font-weight: 500;
                    color: $black-color;
                    margin-bottom: 20px;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: 4px solid $white-color;
                    padding: 0;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                    &.active {
                        color: #495057;
                        background-color: transparent;
                        border-bottom: 4px solid #2e5233;
                    }
                }
            }
            .vision {
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 35px;
                        line-height: 1.7;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        i {
                            font-size: 25px;
                            position: absolute;
                            left: 0;
                            color: $main-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Services Area
=================================================*/
.services-area {
    background: $black-color;

    .section-title {
        h2 {
            color: $white-color;
        }
    }
    .view-btn {
        text-align: center;

        .default-btn {
            border: 1px solid $main-color;
            color: $main-color;
            padding: 15px 30px;
            display: inline-block;

            &:hover {
                border: 1px solid $main-color;
                background: $main-color;
                color: $white-color;
            }
        }
    }
    &.services-two {
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-top: 195px;

        &::before {
            content: "";
            position: absolute;
            background: $black-color;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.7;
        }
        .section-title {
            position: relative;
        }
        .services-slider {
            .service-card {
                margin: 0;

                i {
                    background: #f2e5ff;
                    color: $optional-color;
                }
                &:hover {
                    i {
                        background: $optional-color;
                        color: $white-color;
                    }
                }
            }
            &.owl-theme {
                .owl-nav {
                    margin: 0;

                    .owl-prev,
                    .owl-next {
                        position: absolute;
                        margin: 0;
                        left: -60px;
                        top: 50%;
                        transform: translateY(-50%);
                        border: 1px solid $main-color;
                        color: $main-color;
                        width: 40px;
                        height: 40px;
                        font-size: 30px;
                        line-height: 45px;
                        border-radius: 50%;
                        transition: all 0.7s;

                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                    .owl-next {
                        left: auto;
                        right: -60px;
                    }
                }
            }
        }
    }
}
.service-card {
    text-align: center;
    background: $white-color;
    padding: 35px 15px;
    border-radius: 4px;
    margin-bottom: 30px;

    i {
        color: $white-color;
        background: $optional-color;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50%;
        font-size: 60px;
        margin-bottom: 25px;
        transition: $transition;
    }
    h3 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 12px;
    }
    p {
        font-size: 14px;
        margin: 0;
    }
    .default-btn-two {
        border-color: $black-color;
        color: $black-color;
        border-radius: 4px;
        margin-top: 20px;
        padding: 10px 20px;
        background-color: transparent;
    }
    &:hover {
        i {
            background: $black-color;
            color: $main-color;
        }
        .default-btn-two {
            background: $main-color;
            color: $white-color;
            border-color: $main-color;
        }
    }
}

/*================================================
 partner Slider Area
=================================================*/
.partner-area {
    .partner-slider-item {
        img {
            width: auto;
            margin: auto;
        }
    }
}

/*================================================
Footer Area
=================================================*/
.footer-area {
    background: #f5f5f5;

    .footer-widget {
        margin-bottom: 30px;

        .logo {
            margin-bottom: 20px;
        }
        p {
            color: #7a7a7a;
        }
        h3 {
            font-size: 20px;
            color: $black-color;
            margin-bottom: 25px;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
        }
        .footer-text {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                color: #d3d3d3;
                margin-bottom: 10px;
                transition: $transition;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: #7a7a7a;

                    &:hover {
                        color: $main-color;
                        padding-left: 5px;
                    }
                }
            }
        }
        &.pl-80 {
            padding-left: 80px;
        }
        &.pl-50 {
            padding-left: 50px;
        }
        .info-list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding-left: 35px;
                position: relative;
                margin-bottom: 15px;

                a {
                    color: #7a7a7a;

                    &:hover {
                        color: $main-color;
                        padding-left: 10px;
                    }
                }
                i {
                    color: $main-color;
                    font-size: 16px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                    background: $white-color;
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 4px;
                    transition: $transition;
                    box-shadow: 0px 0px 20px 0px #d8d8d8;

                    &:hover {
                        background: $main-color;
                        color: $white-color;
                    }
                }
                span {
                    display: block;
                    color: $white-color;
                    font-size: 16px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .footer-socials {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            padding: 0 !important;
            margin: 0 10px 0 0;

            &:last-child {
                margin: 0;
            }
            a {
                i {
                    display: inline-block;
                    background: $white-color;
                    color: $black-color;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 5px;
                    font-size: 20px;
                    position: relative;
                    margin-top: 10px;
                    transition: $transition;

                    &:hover {
                        background: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    &.footer-two {
        padding-top: 170px;
    }
}

/*================================================
Preloader Area
=================================================*/
.preloader {
    position: fixed;
    z-index: 99999999;
    background-color: $white-color;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid $main-color;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $main-color transparent transparent transparent;

            &:nth-child(1) {
                animation-delay: -0.45s;
            }
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*================================================
Page banner Area
=================================================*/
.page-banner {
    background-size: cover;
    background-position: center center;
    height: 450px;
    text-align: center;

    &.bg-1 {
        background-image: url("assets/img/page-banner/contact-us-banner.png");
    }
    &.bg-3 {
        background-image: url("assets/img/page-banner/banner3.jpg");
    }
    .page-content {
        margin-top: 115px;
        background-color: rgba(46, 82, 51, 0.9);
        max-width: 970px;
        padding: 40px 20px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;

        h2 {
            color: $white-color;
            position: relative;
            text-transform: capitalize;
            font-size: 35px;
            margin-bottom: 15px;
        }
        ul {
            position: relative;
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                font-size: 15px;
                margin-right: 15px;
                text-transform: capitalize;
                color: $white-color;

                &:last-child {
                    margin-right: 0;
                }
                &::before {
                    content: "/";
                    position: absolute;
                    right: -13px;
                }
                &:last-child::before {
                    display: none;
                }
                a {
                    color: $white-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
About Safe Area
=================================================*/
.about-text-area {
    .about-safe-text {
        margin-bottom: 45px;

        h2 {
            font-size: 30px;
            margin-bottom: 15px;
            line-height: 1.4;
        }
    }
    .shipping-card {
        bottom: 0;
        position: relative;
        padding: 0;
        box-shadow: none;
        background: none;
    }
    .safe-image {
        img {
            border-radius: 5px;
        }
    }
}

/*================================================
Single Blog Area
=================================================*/
.service-details-text {
    .service-image {
        margin-bottom: 30px;

        img {
            border-radius: 5px;
        }
    }
    h1 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
    }
    h6 {
        font-size: 15px;
        font-weight: 600;
    }
    .image {
        margin: 20px 0;

        img {
            border-radius: 5px;
        }
    }
}
.service-sidebar {
    .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                background-color: #f9f8fb;
                padding: 15px 20px 15px 50px;
                border-radius: 5px;
                transition: $transition;
                position: relative;
                color: $black-color;
                display: block;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
            i {
                color: $main-color;
                position: absolute;
                left: 20px;
                top: 13px;
                font-size: 23px;
            }
        }
    }
}

/*================================================
Contact Area
=================================================*/
.contact-info {
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
    border: 1px solid #eeeeee;

    i {
        background-color: #e8f5e9;
        color: $main-color;
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 70px;
        text-align: center;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;

        a {
            color: $body-color;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Error Area
=================================================*/
.error-area {
    .error {
        text-align: center;

        .error-image {
            margin-bottom: 20px;
        }
        h2 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 10px;
            margin-bottom: 20px;
        }
        .error-btn {
            a {
                display: inline-block;
                transition: $transition;
                font-size: 36px;
                font-weight: 600;
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*================================================
Gallery Area
=================================================*/
.gallery-area {
    .gallery-title {
        text-align: center;
        margin-bottom: 30px;
    }
    .floza-gallery {
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;

        .caption {
            text-align: center;
            position: absolute;
            background: rgba(46, 82, 51, 0.75);
            width: 100%;
            height: 100%;
            top: 0;
            left: 100%;
            //transition: $transition;

            a {
                i {
                    color: $main-color;
                    font-size: 30px;
                }
            }
        }
        &:hover .caption {
            left: 0;
        }
    }
}
